import React from "react";

const ReasonsToInvest = [
  {
    title: "A trustworthy brand",
    details:
      "Buy gold from a trustable products provider that is fully licensed as a bullion merchant in Nigeria and operates in line with the best standards and practices in adherence to all local and international regulations.",
  },
  {
    title: "Fairness & integrity",
    details:
      "Our pricing, which is based on the LBMA spot price, is fair, transparent, and competitive with no hidden fees. Our products are responsibly sourced from LBMA accredited refiners ensuring that we sell products of highest quality to you. We offer buyback on all our products at the best rate market rates.",
  },
  {
    title: "Ease of trading",
    details:
      "Using our state-of-the-art, secure, and intuitive platform, you can buy, sell and gift gold and do more at the tap of a button.",
  },
  {
    title: "Assured safekeeping of your assets",
    details:
      "For your peace of mind, your gold holdings are safe, secure, and insured with our designated 3rd party LBMA vaulting service provider. Products in transit are discreetly packed in a tamper-proof packaging and safely delivered to you.",
  },
  {
    title: "Risk control and management",
    details:
      "At Dukia Gold, we take seriously the safeguarding of our customers interests and we have established frameworks and controls for risk management on an ongoing basis taking into account operational risks, unlikely event of winding-down, legal and compliance risks.",
  },
];

const WhyInvest = () => {
  return (
    <div
      id="WhyInvest"
      className="grid z-30 gap-8 max-w-full md:before:-z-30 md:before:w-[90%] lg:before:h-[650px] md:before:h-[750px]  md:before:translate-x-20 md:before:-translate-y-20 md:before:bg-sky-200 md:before:bg-opacity-50 md:before:rounded-[30px] md:before:absolute relative">
      <section className="grid place-content-center gap-2">
        <hr className="w-10 h-1 bg-black mx-auto" />
        <h2 className="text-black font-bold lg:text-4xl md:text-2xl text-center text-xl">
          Why Invest in Us
          <span className="text-red-700 translate-x-8 rotate-[6deg] font-extrabold scale-125">
            ?
          </span>
        </h2>
        <hr className="w-10 h-1 bg-black mx-auto" />
      </section>

      <section className="flex w-full gap-6 justify-center px-[5vw] flex-wrap">
        {ReasonsToInvest.map((reason, i) => (
          <article
            key={i}
            className="flex ease-in-out font-sans w-full lg:max-w-[400px] md:max-w-[300px] gap-4 duration-300 rounded-lg p-6 flex-col justify-center cursor-pointer bg-white shadow-md hover:shadow-lg hover:scale-105">
            <h1 className="font-bold text-xl text-blue-800 md:text-2xl lg:text-3xl">
              {reason.title}
            </h1>
            <p className="md:text-lg">{reason.details}</p>
          </article>
        ))}
      </section>
    </div>
  );
};

export default WhyInvest;
