import React from "react";

const Hero = () => {
  return (
    <div className="text-white grid p-4 bg-blue-800 max-w-full">
      <div className="grid gap-2 w-[60%]">
        <h1 className="lg:text-8xl font-bold md:text-6xl text-3xl">
          Buy & Sell Gold
        </h1>

        <p className="lg:text-3xl md:text-xl text-base">
          ⦁ Dukia Gold, the Premier Full-Service Bullion Merchant in Nigeria,
          West Africa.
        </p>
        <p className="lg:text-xl md:lg text-sm">
          ⦁ Creating ease of access to investment grade gold in Nigeria via a
          safe and secure trading platform.
        </p>
        <p className="lg:text-lg md:text-base text-xs">
          ⦁ Fully licensed by the Ministry of Mines and Steel Development
        </p>
        <p className="lg:text-lg md:text-base text-xs">
          ⦁ Registered with the Special Control Unit Against Money Laundering
          (SCUML) and comply with strict regulations regarding AML & KYC
          procedures
        </p>
      </div>
    </div>
  );
};

export default Hero;
