import React from "react";
import { data } from "../Data/Combo";

const ProductPreview = () => {
  return (
    <div className="overflow-x-scroll gap-6 scrollbar-hide scroll p-4 h-[max-content] flex">
      <div className="flex gap-4">
        {data.map((data) => (
          <article
            key={data.id}
            className="flex gap-2 items-center h-full justify-between rounded-lg shadow-md bg-white flex-col p-3 md:w-[20vw] w-[15rem]">
            <img src={data.image} alt="" width={80} height={100} />
            <div className="grid gap-2">
              <p className="font-bold">{data.title}</p>
              <div className="flex w-full justify-end">
                <button className="px-6 py-1 rounded-md text-white hover:scale-105 hover:text-yellow-500 bg-yellow-500 hover:bg-white outline outline-1 outline-yellow-500 w-[max-content]">
                  Buy
                </button>
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};
const Products = () => {
  return (
    <>
      <div id="Products">
        <section className="grid place-content-center gap-2">
          <hr className="w-10 h-1 bg-black mx-auto" />
          <h2 className="text-black font-bold lg:text-4xl md:text-2xl text-center text-xl">
            Featured Products
            <span className="text-red-700 translate-x-8 rotate-[6deg] font-extrabold scale-125">
              ?
            </span>
          </h2>
          <hr className="w-10 h-1 bg-black mx-auto" />
        </section>
      </div>
      <ProductPreview />
    </>
  );
};

export default Products;
