import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiFacebookFill } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";

const Subscription = () => {
  return (
    <div className="w-[100%] grid gap-6 content-center">
      <div>
        <p className="text-white font-bold text-xl">Subscribe</p>
        <p>Subscribe to our Newsletter...!</p>
      </div>

      <form className="md:flex flex-col flex w-[100%] content-center gap-3 ">
        <div className="sm:flex-row flex flex-col gap-2">
          <label>
            <input
              type="email"
              placeholder="example@gmail.com"
              className="p-2 md:w-full w-[80%] rounded-md outline-none w-20] focus:shadow-md caret-blue-800"
            />
          </label>

          <label>
            <input
              type="text"
              placeholder="First name"
              className="p-2 md:w-full w-[80%] rounded-md outline-none w-20] focus:shadow-md caret-blue-800"
            />
          </label>
        </div>

        <button className="p-1.5 w-[max-content] text-center duration-300 hover:text-yellow-500 hover:bg-white outline outline-[1px] outline-yellow-500 font-semibold px-9 bg-yellow-500 text-white text-lg rounded-md">
          Subscribe
        </button>
      </form>
      {/* Needed to add Social media links */}
      <div className="flex justify-start gap-3">
        <AiOutlineInstagram size={20} />
        <RiFacebookFill size={20} />
        <FaTiktok size={20} />
      </div>
    </div>
  );
};

export default Subscription;
