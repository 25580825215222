import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useContext } from "react";
import DataContext from "../../DataContext/DukiaContext";
import BuyGoldMenu from "../BuyGoldMenu";
import GuideMenu from "../GuideMenu";

const MenuBar = () => {
  const {
    closeGoldMenu,
    closeGuideMenu,
    state,
    toggleGuideMenu,
    toggleGoldMenu,
  } = useContext(DataContext);

  // const gdMenu = useClickOutSide(() => {
  //   closeGuideMenu();
  // });

  // const gMenu = useClickOutSide(() => {
  //   closeGoldMenu();
  // });

  let gMenu = useRef();
  let gdMenu = useRef();
  useEffect(() => {
    let checkHandler = (event) => {
      if (!gMenu.current.contains(event.target)) {
        closeGoldMenu();
      }
    };

    document.addEventListener("mousedown", checkHandler);

    return () => {
      document.removeEventListener("mousedown", checkHandler);
    };
  });
  useEffect(() => {
    let checkHandler = (event) => {
      if (!gdMenu.current.contains(event.target)) {
        closeGuideMenu();
      }
    };

    document.addEventListener("mousedown", checkHandler);

    return () => {
      document.removeEventListener("mousedown", checkHandler);
    };
  });

  return (
    <div className=" md:flex hidden h-[fit-content] pt-2 md:gap-2 xl:gap-4 w-[fit-content]">
      <Link to="/" className="hover:underline">
        <strong>HOME</strong>
      </Link>

      <div
        ref={gMenu}
        onClick={() => {
          toggleGoldMenu();
        }}
        className="flex gap-1 hover:underline cursor-pointer relative">
        <strong>BUY GOLD</strong>
        <MdKeyboardArrowDown
          className="rounded-full hover:shadow-md"
          size={20}
        />
        {state.goldMenu && <BuyGoldMenu />}
      </div>

      <Link to="/" id="About" className="hover:underline">
        <strong>ABOUT US</strong>
      </Link>

      <div
        className="flex gap-1 relative hover:underline cursor-pointer"
        ref={gdMenu}
        onClick={() => {
          toggleGuideMenu();
        }}>
        <strong>GUIDE</strong>
        <MdKeyboardArrowDown
          className=" rounded-full hover:shadow-md"
          size={20}
        />
        {state.guideMenu && <GuideMenu />}
      </div>
    </div>
  );
};

export default MenuBar;
