import React from "react";

const Register = () => {
  return (
    <div>
      <div className="h-[max-content] w-fit bg-white mx-auto my-10 place-content-center shadow-lg ">
        <form
          onClick={(e) => e.preventDefault()}
          className="flex h-[100%] content-center flex-col ">
          {/* <div className=" bg-blue-900 w-[100%] min-h-[100%] flex flex-col content-center text-center justify-center gap-4 p-5 text-white">
            <hr className="w-[3vw] h-[1.2px]  mx-auto rounded-full bg-white h-1" />
            <strong className="text-2xl">WELCOME TO DUKIA</strong>
            <p className="mt-[-8px]">Register a Dukia Account</p>
            <hr className="w-[3vw] h-1 mx-auto rounded-full bg-white h-1" />
          </div> */}

          <div className=" h-[fit-content]  px-8 w-[100%] grid gap-5 py-10">
            <div className="grid text-center">
              <h1>
                <strong className="text-2xl text-blue-800">
                  Create an Account
                </strong>
              </h1>
              <p className="pb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididun
              </p>

              <hr className="w-[4vw] h-1.5 mx-auto rounded-full bg-red-900" />
            </div>

            <label className="grid gap-3">
              <strong>Name*</strong>
              <input
                required
                type="text"
                placeholder="John Doe"
                className=" border-[1px] focus:shadow-md outline-none rounded-lg px-2 p-2"
              />
            </label>

            <label className="grid gap-3">
              <strong>Email*</strong>
              <input
                type="email"
                required
                placeholder="example@gmail.com"
                className=" border-[1px] focus:shadow-md outline-none rounded-lg px-2 p-2"
              />
            </label>

            <label className="grid gap-3">
              <strong>Password*</strong>
              {/* Allow password to be visible */}
              <input
                type="password"
                required
                placeholder="Your Password"
                className="focus:shadow-md border rounded-lg outline-none px-2 p-2"
              />
            </label>

            <label className="grid gap-3">
              <strong>Confirm Password*</strong>
              {/* Allow password to be visible */}
              <input
                type="password"
                required
                placeholder="Your Password"
                className="focus:shadow-md border rounded-lg outline-none px-2 p-2"
              />
            </label>

            {/* Work on how to confirm the password before proceeding */}
            <button
              type="submit"
              className="text-md px-10 mx-auto hover:bg-white hover:border-yellow-500 hover:outline hover:outline-[1px] hover:text-yellow-500 w-[fit-content] p-1 bg-yellow-500 rounded-md text-white">
              <strong>Sign Up</strong>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
