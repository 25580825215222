import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <div className="grid gap-2">
      <p>
        <strong className="text-white  text-xl">Navigation</strong>
      </p>
      <ul className="flex flex-col gap-2 ">
        <li className="w-[fit-content] hover:text-blue-300">
          <a href="#Buy">Buy Gold</a>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <Link to="/ShoppingCart">Cart</Link>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <a href="#Products">Products</a>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <a href="#WhyInvest">Why Invest?</a>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <a href="#CustomerReview">Customer reviews</a>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <Link to="/Faq">FAQ</Link>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
