import React from 'react';
import Breadcrumb from '../Components/Breadcrumb';
import {data} from '../Data/GoldBarData'
// 'blue':"#244D91",
    //   "yellow":"#A69145"
const Goldbar = () => {
  return (
    <main className=' w-[90%] mx-auto'>
      <Breadcrumb/>
      <div className='py-4'>
        <div className='text-center border-b border-b-gray-400 pb-6 '>
          <h2 className='capitalize text-[#244D91]'>Buy gold coins online</h2>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores unde fugiat iusto eveniet porro vitae rerum ea id iure labore.</p>
        </div>
        <div className='py-[2rem] grid gap-[2rem] sm:grid-cols-2 lg:grid-cols-3 justify-center sm:justify-between items-center w-full my-6'>
          {data.map(gold=>{
            return (
              <div key={gold.id} className='w-full shadow-md mx-auto flex flex-col items-center justify-around rounded-md bg-white'>
                <div className='grid justify-center items-center w-full h-[15rem]'>
                  <img src={gold.image} alt={gold.title} className='w-[6rem] h-[11rem] mx-auto object-contain'/>
                </div>
                  <div className='border-t border-t-gray-400 w-full px-6 py-4 h-[8rem] flex flex-col justify-between'>
                    <h5>{gold.heading}</h5>
                    <div className='flex justify-between items-center'>
                        <p>$110.00</p>
                        <button className='bg-blue py-2 bg-[#A69145] text-white rounded-md px-4'>Buy Now</button>
                  </div>
                </div>
            </div>
            )
          })}
        </div>
      </div>
    </main>
  )
}

export default Goldbar