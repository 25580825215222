import React from "react";

const ForgotPassword = () => {
  return (
    <div>
      <p className="text-3xl">Forgot password</p>
    </div>
  );
};

export default ForgotPassword;
