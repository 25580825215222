import { createContext, useReducer } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  //codes and constants goes in here...
  const States = {
    HASACCOUNT: "has account",
    DONOTHASACCOUNT: "do no have account",
    CLOSEGOLDMENU: "gold menu",
    CLOSEGUIDEMENU: "guide menu",
    TOGGLEGUIDEMENU: "toggle guide state",
    TOGGLEGOLDMENU: "toggle gold state",
    TOGGLESIDEBAR: "toggle side bar",
    CLOSESIDEBAR: "close side bar",
    LOGGEDIN: "show profile bar",
    LOGOUT: "hide profile bar",
    USERNAME: "switch user name",
    REF: "switch ref point",
    QUANTITY: "number of products in purchase",
    Q_INCREMENT: "increment",
    Q_DECREMENT: "decrement",
    RESETQUANTITY: "reset quantity to zero",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case States.HASACCOUNT:
        return { ...state, hasAccount: true };
      case States.DONOTHASACCOUNT:
        return { ...state, hasAccount: false };
      case States.CLOSEGUIDEMENU:
        return { ...state, guideMenu: false };
      case States.TOGGLEGUIDEMENU:
        return { ...state, guideMenu: !state.guideMenu };
      case States.CLOSEGOLDMENU:
        return { ...state, goldMenu: false };
      case States.TOGGLEGOLDMENU:
        return { ...state, goldMenu: !state.goldMenu };
      case States.CLOSESIDEBAR:
        return { ...state, sideBar: false };
      case States.TOGGLESIDEBAR:
        return { ...state, sideBar: !state.sideBar };
      case States.LOGGEDIN:
        return { ...state, loggedIn: true };
      case States.LOGOUT:
        return { ...state, loggedIn: false };
      case States.USERNAME:
        return { ...state, userName: action.payload };
      case States.REF:
        return { ...state, ref: action.payload };
      case States.Q_INCREMENT:
        return { ...state, quantity: state.quantity + 1 };
      case States.Q_DECREMENT:
        return { ...state, quantity: state.quantity - 1 };
      case States.RESETQUANTITY:
        return { ...state, quantity: 0 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    hasAccount: true,
    guideMenu: false,
    sideBar: false,
    goldMenu: false,
    loggedIn: false,
    userName: "username",
    ref: "",
    quantity: 0,
  });

  // useReducer functions
  const SignUp = () => {
    dispatch({ type: States.DONOTHASACCOUNT });
  };

  const LogIn = () => {
    dispatch({ type: States.HASACCOUNT });
  };

  const closeGuideMenu = () => {
    dispatch({ type: States.CLOSEGUIDEMENU });
  };

  const toggleGuideMenu = () => {
    dispatch({ type: States.TOGGLEGUIDEMENU });
  };

  const closeGoldMenu = () => {
    dispatch({ type: States.CLOSEGOLDMENU });
  };

  const toggleGoldMenu = () => {
    dispatch({ type: States.TOGGLEGOLDMENU });
  };

  const hideProfileBar = () => {
    dispatch({ type: States.LOGOUT });
  };

  const showProfileBar = () => {
    dispatch({ type: States.LOGGEDIN });
  };

  const updateUserName = (e) => {
    dispatch({ type: States.USERNAME, payload: e.target.value });
  };

  const setRef = (e) => {
    dispatch({ type: States.REF, payload: e.target.ref });
  };

  const closeSideBar = () => {
    dispatch({ type: States.CLOSESIDEBAR });
  };

  const toggleSideBar = () => {
    dispatch({ type: States.TOGGLESIDEBAR });
  };

  const increaseQuantity = () => {
    dispatch({ type: States.Q_INCREMENT });
  };
  const decreaseQuantity = () => {
    dispatch({ type: States.Q_DECREMENT });
  };

  const resetQuantity = () => {
    dispatch({ type: States.RESETQUANTITY });
  };

  // This hook closes the drop down & sidebar when a click happens outside of it
  // const useClickOutSide = (handler) => {
  //   state.ref = useRef();
  //   useEffect(() => {
  //     let checkHandler = (e) => {
  //       if (!state.ref.current.contains(setRef(e))) {
  //         handler();
  //       }
  //     };

  //     document.addEventListener("mousedown", checkHandler);

  //     return () => document.removeEventListener("mousedown", checkHandler);
  //   });

  //   return state.ref;
  // };

  return (
    <DataContext.Provider
      value={{
        setRef,
        state,
        SignUp,
        LogIn,
        toggleGuideMenu,
        toggleGoldMenu,
        hideProfileBar,
        showProfileBar,
        updateUserName,
        closeGoldMenu,
        closeGuideMenu,
        toggleSideBar,
        closeSideBar,
        increaseQuantity,
        decreaseQuantity,
        resetQuantity,
      }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
