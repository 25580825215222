import React from "react";
import { Link } from "react-router-dom";
// import { MdKeyboardArrowDown } from "react-icons/md";
import { HashLink } from "react-router-hash-link";

const SideBar = () => {
  return (
    <ul
      className={`transition absolute py-12 duration-300 opacity-[95%] ease-in-out flex gap-4 bg-blue-800 bg-opacity-80 text-white w-[max-content] top-10 left-[-15px] z-30  flex-col md:hidden h-[max-content]`}>
      <li>
        <Link to="/">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            HOME
          </p>
        </Link>
      </li>

      <li>
        <Link to="/" id="Buy">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            BUY GOLD
          </p>
        </Link>
      </li>

      <li>
        <Link to="/" id="About">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            ABOUT US
          </p>
        </Link>
      </li>

      <li>
        <HashLink to="/" id="About">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            HOW DOES IT WORKS?
          </p>
        </HashLink>
      </li>

      <li>
        <HashLink to="/" id="About">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            WHY INVEST IN GOLD
          </p>
        </HashLink>
      </li>

      <li>
        <HashLink to="/" id="About">
          <p className="p-2 px-6 font-bold hover:bg-yellow-600 rounded-md">
            FAQ
          </p>
        </HashLink>
      </li>

      <li>
        <HashLink to="/" id="About">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            DOWNLOADS
          </p>
        </HashLink>
      </li>

      <li>
        <Link to="/" id="Guide">
          <p className="p-2 px-6 font-bold hover:bg-yellow-500 rounded-md">
            GUIDE
          </p>
        </Link>
      </li>
    </ul>
  );
};

export default SideBar;
