import React from "react";

const Consultation = () => {
  return (
    <div
      id="consultation-tag"
      className="grid z-10 md:gap-0 gap-6 place-content-center md:grid-cols-2 max-w-full overflow-x-hidden before:overflow-x-hidden md:before:w-full md:before:h-[400px] md:before:bg-slate-400 md:before:flex before:hidden md:before:rotate-[4deg] md:before:rounded-md md:before:absolute md:before:-z-10">
      <section className="grid w-[100%] gap-2 text-center content-center">
        <hr className="w-10 bg-black mx-auto h-1 rounded-full" />
        <h1 className="font-bold font-aria text-2xl text  text-blue-900 ">
          Book A Consultation
        </h1>
        <p className="font-bold">
          Looking for a free consultation or to request a quote?
        </p>
        <hr className="w-10  mx-auto  bg-black h-1 rounded-full" />
      </section>

      <section className="grid md:w-full w-[max-content] p-8 bg-blue-900 rounded-2xl text-white justify-start">
        <form className="grid gap-6">
          <p>⦁ Book a call with our team of experts and we will be in touch </p>

          <label>
            <p>Name*</p>
            <input
              type="text"
              placeholder="John Doe"
              className="p-1 border w-full outline-none my-2 rounded-lg focus:shadow-md caret-blue-700"
            />
          </label>

          <label>
            <p>Email*</p>
            <input
              type="email"
              placeholder="example@gmail.com"
              className="p-1 w-full border my-2 outline-none rounded-lg focus:shadow-md caret-blue-700"
            />
          </label>

          <label>
            <select className="text-black w-full text-lg p-2 outline-none focus:shadow-md rounded-md">
              <option value="Book a consultation">Book a consultation</option>
              <option value="Request a quote">Request a quote</option>
            </select>
          </label>

          <label>
            <p>Message</p>
            <textarea
              placeholder="Write your message...!"
              className="p-2 my-2 font-serif w-full rounded-lg outline-none border focus:shadow-md caret-blue-700"
            />
          </label>

          <button
            type="submit"
            className="bg-yellow-500 mx-auto text-lg px-14 py-2 font-bold rounded-xl w-[fit-content] text-white hover:text-yellow-500 hover:bg-white  outline-none hover:outline-1">
            Book
          </button>
        </form>
      </section>
    </div>
  );
};

export default Consultation;
