import React, { useContext, useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsCart4 } from "react-icons/bs";
import DataContext from "../../DataContext/DukiaContext";
import { RiArrowDropDownLine } from "react-icons/ri";

const Right = () => {
  // const [showProfile, setShowProfile] = useState(false);
  const { state, hideProfileBar } = useContext(DataContext);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  let menu = useRef();
  useEffect(() => {
    let checkHandler = (event) => {
      if (!menu.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", checkHandler);

    return () => {
      document.removeEventListener("mousedown", checkHandler);
    };
  });

  return (
    <div className="flex md:gap-10 gap-5 justify-end duration-300 ease-in-out pt-1 md:w-full w-[fit content]">
      <div className="flex content-center">
        <input
          placeholder="Search for a product"
          type="search"
          className="md:w-full w-[100%] rounded-full  px-4 p-1 border outline-none focus:shadow-md"
        />
        {/* <BsSearch size={12} /> */}
      </div>

      <div className="md:flex hidden relative ">
        <Link to="/ShoppingCart">
          <p className="w-5 h-5 text-center absolute top-[-4px] right-[-5px] bg-yellow-500 text-white rounded-full text-sm">
            0
          </p>
          <BsCart4 size={20} />
        </Link>
      </div>
      <div className="relative">
        {!state.loggedIn ? (
          <div className="flex justify-between w-[fit-content] gap-5">
            <Link to="/login&signUp">
              <button className="bg-white text-blue-800 border duration-300 ease-in-outs px-7 p-2 rounded-md border-blue-800 hover:bg-blue-800 hover:text-white ">
                Login
              </button>
            </Link>
            <Link to="/login&signUp">
              <button className=" bg-yellow-500 p-2 px-5 w-[max-content] duration-300 ease-in-outs text-white border rounded-md hover:text-yellow-500 hover:bg-white hover:border-yellow-500">
                Get Started
              </button>
            </Link>
          </div>
        ) : (
          <div className="flex gap-4">
            <img
              src="https://imgs.search.brave.com/FVPHKygV2rAkZTJPzdavwYuQCgnSwIfqTwifcrGnC5M/rs:fit:713:225:1/g:ce/aHR0cHM6Ly90c2Uz/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5n/MW0wSzd5dW1md2tj/X3ViMjI0YTRBSGFF/NyZwaWQ9QXBp"
              alt="Profile Img"
              className="w-10 h-10 rounded-full"
            />

            <div
              ref={menu}
              onClick={() => {
                setShowProfileMenu(!showProfileMenu);
              }}>
              <div className="flex md:text-base text-sm gap-2">
                <p>{`Hi ${state.userName}`}</p>
                <RiArrowDropDownLine
                  className="rounded-full border hover:shadow-md"
                  size={25}
                />
              </div>

              {showProfileMenu && (
                <div
                  className={` z-100 rounded-sm absolute text-center py-4  w-[max-content] opacity-[95%] bg-opacity-60 gap-4 bg-blue-800 text-white bottom-[-170px]`}>
                  <ul className="grid gap-3">
                    <li className="Hover:shadow-md p-2 hover:bg-yellow-500 font-bold rounded-md hover:text-white">
                      <Link to="/">Change Password</Link>
                    </li>
                    <li className="Hover:shadow-md p-2 font-bold rounded-md hover:bg-yellow-500 hover:text-white">
                      <Link to="/">Profile</Link>
                    </li>
                    <li className="Hover:shadow-md p-2 hover:bg-yellow-500 rounded-md font-bold hover:text-white">
                      <Link to="/">Setting</Link>
                    </li>
                    <li
                      onClick={() => hideProfileBar()}
                      className="Hover:shadow-md p-2 hover:bg-yellow-500 rounded-md font-bold hover:text-white">
                      <Link to="/">Log Out</Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Right;
