import React from "react";

const AboutUs = () => {
  return (
    <div>
      <p className="text-3xl">About Us</p>
    </div>
  );
};

export default AboutUs;
