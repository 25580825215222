import React from "react";

const CustomerReview = () => {
  return (
    <div id="CustomerReview" className="grid gap-[2rem]">
      <section className="grid place-content-center gap-2">
        <hr className="w-10 h-1 bg-black mx-auto" />
        <h2 className="text-black font-bold lg:text-4xl md:text-2xl text-center text-xl">
          Customers Review
          <span className="text-red-700 translate-x-8 rotate-[6deg] font-extrabold scale-125">
            ?
          </span>
        </h2>
        <hr className="w-10 h-1 bg-black mx-auto" />
      </section>

      <div className="flex flex-wrap justify-center w-[90vw] mx-auto gap-[3rem] md:flex-row">
        <article className="grid bg-white p-6 rounded-lg w-full mx-auto md:w-[30rem] shadow-md hover:scale-105 hover:shadow-lg duration-300 ease-in-out">
          <div className="flex justify-between">
            <div className="font-bold">
              <p className="md:text-4xl text-2xl  w-[max-content] text-blue">
                Customer Name
              </p>
              <p className="text-xl">Country</p>
            </div>
            <p className="text-blue-800 text-9xl font-serif font-extrabold">
              &#10078;
            </p>
          </div>
          <p className="md:text-xl text-base font-serif">
            Lorem ksunef ifhaf igf wfiufa isfwfifhaf ishf akhaf ifjaf kfjf
            wfiufa isfwfifhaf ishf akhaf ifjaf kfjf
          </p>
        </article>
        <article className="grid bg-white p-6 rounded-lg w-full mx-auto md:w-[30rem] shadow-md hover:scale-105 hover:shadow-lg duration-300 ease-in-out">
          <div className="flex justify-between">
            <div className="font-bold">
              <p className="md:text-4xl text-2xl  w-[max-content] text-blue">
                Customer Name
              </p>
              <p className="text-xl">Country</p>
            </div>
            <p className="text-blue-800 text-9xl font-serif font-extrabold">
              &#10078;
            </p>
          </div>
          <p className="md:text-xl text-base font-serif">
            Lorem ksunef ifhaf igf wfiufa isfwfifhaf ishf akhaf ifjaf kfjf
            wfiufa isfwfifhaf ishf akhaf ifjaf kfjf
          </p>
        </article>
        <article className="grid bg-white p-6 rounded-lg w-full mx-auto md:w-[30rem] shadow-md hover:scale-105 hover:shadow-lg duration-300 ease-in-out">
          <div className="flex justify-between">
            <div className="font-bold">
              <p className="md:text-4xl text-2xl  w-[max-content] text-blue">
                Customer Name
              </p>
              <p className="text-xl">Country</p>
            </div>
            <p className="text-blue-800 text-9xl font-serif font-extrabold">
              &#10078;
            </p>
          </div>
          <p className="md:text-xl text-base font-serif">
            Lorem ksunef ifhaf igf wfiufa isfwfifhaf ishf akhaf ifjaf kfjf
            wfiufa isfwfifhaf ishf akhaf ifjaf kfjf
          </p>
        </article>
      </div>
    </div>
  );
};

export default CustomerReview;
