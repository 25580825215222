import React from "react";

const Faq = () => {
  return (
    <div id="Fag">
      <p className="text-3xl">FAG</p>
    </div>
  );
};

export default Faq;
