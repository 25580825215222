export const data = [
  {
    sell: -1.4,
    buy: 32.46,
    id: 1,
    title: "1 Gram Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "1 Gram Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675706558/1-Gram-Philoro-Gold-Minted-Bar_xdylig.jpg",
    fineWeight: "1 g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 1 Gram minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. \n \n The front face also shows the bar's weight of 1 Gram, fine gold content of 999.9, and a unique serial number imprinted on it. The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. \n \n Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 8,
    type: "coin",
    title: "Gold Philharmonic 1 oz Coin",
    heading: "Gold Philharmonic 1 oz Coin",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675667595/dukia-Gold-Philharmonic-1-oz-Coin_qrpcgh.jpg",
    image2:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675667596/dukia-Gold-Philharmonic-1-oz-Coin-2_ikmd4y.jpg",
    fineWeight: "1.00 oz",
    grossWeight: "31.1 g",
    countryOfOrigin: "Austria",
    manufacturer: "Münze Österreich",
    fineness: "999.9",
    grade: "New",
    certificate: null,
    packaging: null,
    description:
      "The one-ounce (1 oz) Vienna Philharmonic Gold Coin is an Austrian gold coin with a fineness of 999.9, first issued by Münze Österreich in 1989. The Gold Philharmonic is the second bullion coin made of gold after the Gold Britannia and is legal tender. Since the currency change in 2002, the coin is no longer tagged in Schilling, but in Euro. This coin remains the only bullion coin in Europe with a nominal value in Euro (100 Euro). \n \n The gold coin is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1.4,
    buy: 32.46,
    id: 2,
    title: "10 Grams Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "10 Grams Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675706843/10-Grams-Philoro-Gold-Minted-Bar_erqlo3.jpg",
    fineWeight: "10 g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 10 Grams minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. The front face also shows the bar's weight of 10 Grams, fine gold content of 999.9, and a unique serial number imprinted on it. \n \n The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 9,
    type: "coin",
    title: "Gold Maple Leaf 1 oz Coin",
    heading: "Gold Maple Leaf 1 oz Coin",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675667595/dukia-Gold-Maple-Leaf-1-oz-Coin_bslgny.jpg",
    image2:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675667595/dukia-Gold-Maple-Leaf-1-oz-Coin-2_hepqbi.jpg",
    fineWeight: "1 oz",
    grossWeight: "31.1 g",
    countryOfOrigin: "Canada",
    manufacturer: "Royal Canadian Mint",
    fineness: "999.9",
    grade: "New",
    certificate: null,
    packaging: null,
    description:
      "The one-ounce (1 oz) Gold Maple Leaf Coin is a Canadian coin which has been minted by Royal Canadian Mint since 1979. Therefore, it is one of the oldest gold bullion coins in the world. Next to the Krugerrand, the Maple Leaf gold coin is the most widespread gold coin. \n \n The front of the coin shows a maple leaf which is also depicted on the national flag of Canada. On the back, like on all coins of the Commonwealth, the portrait of Queen Elizabeth II is visible. Since 2013, the 1 ounce version of the Maple Leaf gold coin bears an additional security feature: a small maple leaf with the last two digits of the year of mint. Inscriptions on the coin are written in the two national Canadian languages, English and French. \n \n The gold coin is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 5,
    title: "100 Grams Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "100 Grams Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675707888/100-Grams-Philoro-Gold-Minted-Bar_gn2iau.jpg",
    fineWeight: "100g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 100 Grams minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. The front face also shows the bar's weight of 100 Grams, fine gold content of 999.9, and a unique serial number imprinted on it. \n \n The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 10,
    type: "coin",
    title: "Gold Britannia 1 oz Coin",
    heading: "Gold Britannia 1 oz Coin",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675667596/dukia-Gold-Britannia-1-oz-Coin_qpux3w.jpg",
    image2:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675667595/dukia-Gold-Britannia-1-oz-Coin-2_plwpvv.jpg",
    fineWeight: "1.00 oz",
    grossWeight: "31.1 g",
    countryOfOrigin: "United Kingdom",
    manufacturer: "The Royal Mint",
    fineness: "999.9",
    grade: "New",
    certificate: null,
    packaging: null,
    description:
      "The one-ounce (1 oz) Gold Britannia Coin is a British gold coin which has been minted since 1987 by The Royal Mint and was the first European bullion coin. The value of the Britannia gold coin is marked in British Pound and is legal tender in Great Britain. Since 1987, the coin is available in units of 1/10 ounce, 1/2 ounce and 1 ounce. \n \n Since 2013, the Gold Britannia has a fineness of 999,9. Before that, it was 916,7 — equaling 22 Karat. Until 1989, an alloy of copper (1/12) was used, which gave the coin a reddish colour. Afterwards, copper and silver were used in the same ratio. Since 1997, the Britannia is also available in silver. \n \n The front image of the Britannia gold coin is the Roman goddess and personification of the country “Britannia“ as a standing figure with helmet, shield and trident. The motif remained unchanged up to the Millennium. Since 2001, the motif of the Gold Britannia changes in years with uneven numbers and remains the same in years with even numbers. The portrait of Queen Elizabeth II, which is updated irregularly according to the current appearance of the regent, is pictured on the reverse. \n \n The gold coin is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
];
