import React from "react";

const Address = () => {
  return (
    <div>
      <p>
        <strong className="text-white  text-xl">Address</strong>
      </p>
    </div>
  );
};

export default Address;
