import React from "react";
import { HashLink } from "react-router-hash-link";
import Right from "./HeaderComponents/Right";
import Left from "./HeaderComponents/Left";

const Header = () => {
  return (
    <div className=" flex z-50 bg-opacity-80 max-w-[100%] bg-white flex-col sticky top-0">
      <div></div>
      <p className="p-2 text-white text-center  text-sm bg-blue-900">
        Need help? Get in touch with our support team at
        <HashLink to="/#consultation-tag"
          className="hover:underline text-amber-500"
          href="home#Consultation">
          support@dukiapreciousmetals.co{" "}
        </HashLink>
        or call
        <a
          className="text-amber-500 hover:underline"
          href="tel:+234 803 569 2881">
          +234 803 569 2881
        </a>
        or
        <a
          className="text-amber-500 hover:underline"
          href="tel:+234 703 323
        8121">
          +234 703 323 8121
        </a>
        .
      </p>

      <div className="flex p-5 w-[100%] relative shadow-md justify-between">
        {/*get rid of this div & the paragraph above */}
        <Left />
        <Right />
      </div>
    </div>
  );
};

export default Header;
