export const data = [
  {
    sell: -1.4,
    buy: 32.46,
    id: 1,
    title: "1 Gram Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "1 Gram Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675706558/1-Gram-Philoro-Gold-Minted-Bar_xdylig.jpg",
    fineWeight: "1 g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 1 Gram minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. \n \n The front face also shows the bar's weight of 1 Gram, fine gold content of 999.9, and a unique serial number imprinted on it. The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. \n \n Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1.4,
    buy: 32.46,
    id: 2,
    title: "10 Grams Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "10 Grams Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675706843/10-Grams-Philoro-Gold-Minted-Bar_erqlo3.jpg",
    fineWeight: "10 g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 10 Grams minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. The front face also shows the bar's weight of 10 Grams, fine gold content of 999.9, and a unique serial number imprinted on it. \n \n The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 5,
    title: "100 Grams Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "100 Grams Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675707888/100-Grams-Philoro-Gold-Minted-Bar_gn2iau.jpg",
    fineWeight: "100g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 100 Grams minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. The front face also shows the bar's weight of 100 Grams, fine gold content of 999.9, and a unique serial number imprinted on it. \n \n The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 6,
    title: "500 Grams Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "500 Grams Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675708127/500-Grams-Philoro-Gold-Minted-Bar_nddidp.jpg",
    fineWeight: "500 g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 500 Grams minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. The front face also shows the bar's weight of 500 Grams, fine gold content of 999.9, and a unique serial number imprinted on it. \n \n The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
  {
    sell: -1,
    buy: 11.14,
    id: 7,
    title: "1 Kilogram Philoro Gold Minted Bar of 999.9 fineness",
    type: "bar",
    heading: "1 Kilogram Philoro Gold Minted Bar of 999.9 fineness",
    image:
      "https://res.cloudinary.com/dcu3hr3eo/image/upload/v1675708280/1-Kilogram-Philoro-Gold-Minted-Bar_nznbsd.jpg",
    fineWeight: "1000.00 g",
    countryOfOrigin: "Switzerland",
    manufacturer: "Valcambi S.A.",
    fineness: "999.9",
    grade: "New",
    certificate: "Yes",
    packaging: "Tamper-evident packaging",
    description:
      "The 1 Kilogram minted gold bar from Philoro is a 999.9 fine gold bar with a high-lustre finish and rounded edges. The front surface is imprinted with the Valcambi S.A. refinery mark of the letters 'CHI ESSAYEUR FONDEUR' on the gold bar. The front face also shows the bar's weight of 1 Kilogram, fine gold content of 999.9, and a unique serial number imprinted on it. \n \n The gold bar is VAT free if the secure storage option with Brink’s is opted for. Storage fee is free for the first 12 months of storage. Your specific numbered gold bar will be fully allocated to you in your name and segregated within the vault. \n \n All shipping costs is inclusive of handling, protective packaging, insurance, and VAT. The cost of shipping to your preferred location will be advised upon request.",
  },
];
