import React from "react";
import Address from "./FooterComponents/Address";
import Information from "./FooterComponents/Information";
import Navigation from "./FooterComponents/Navigation";
import Subscription from "./FooterComponents/Subscription";

const Footer = () => {
  return (
    <div className=" flex p-5 flex-col bg-blue-900 gap-2">
      <div className="md:flex grid-cols-2 grid gap-4 w-full text-white mx-auto justify-around">
        <section className="w-[100%]">
          <Address />
        </section>
        <section className="w-[100%]">
          <Navigation />
        </section>
        <section className="w-[100%]">
          <Information />
        </section>
        <section className="w-[100%]">
          <Subscription />
        </section>
      </div>

      <hr className="h-[1px] bg-white" />

      <p className="text-center text-white">
        &copy; Copyright {new Date().getFullYear()} Dukia Gold
      </p>
    </div>
  );
};

export default Footer;
