import React, { useContext } from "react";
import DataContext from "../DataContext/DukiaContext";

const ShoppingCart = () => {
  const { increaseQuantity, decreaseQuantity, state } = useContext(DataContext);
  return (
    <div className="grid lg:w-[70vw] lg:mx-auto h-full gap-4 p-6">
      <p></p>

      <h1 className="text-2xl">Shopping Cart({0})</h1>

      <hr className="w-full h-[2px] bg-black" />

      <div className="flex md:flex-row flex-col justify-between">
        <div className="flex  gap-6">
          <img
            src="https://www.svgrepo.com/show/484569/coin.svg"
            alt=""
            width={80}
          />

          <div className="grid gap-[1rem]">
            <p className="font-semibold">1.0z 24k Gold Coin</p>
            <p className="text-gray-400">
              Denomination: <span className="text-black">$$</span>
            </p>

            <p className="text-gray-400">
              Size: <span className="text-black">M</span>
            </p>

            <p className="text-gray-400">
              Price: <span className="text-black">$110</span>
            </p>

            <form className="grid gap-[2rem]">
              <label>
                <input type="radio" name="delivery_Type" />
                <p className="font-semibold">Delivery</p>
                <p>Receive 1-10 working days depending on location</p>
              </label>

              <label>
                <input type="radio" name="delivery_Type" />
                <p className="font-semibold"> Store with Dukia</p>
              </label>
            </form>
          </div>
        </div>

        <div className="flex flex-col content-center md:mt-0 mt-4 gap-4 justify-between">
          <div className="flex font-semibold content-center md:justify-between justify-start gap-3">
            <p>Quantity:</p>
            <div className="flex h-[max-content] content-center gap-2">
              <button
                onClick={decreaseQuantity}
                className="bg-yellow-500 grid place-content-center text-white p-1 w-[1.5rem] h-[1.5rem] rounded-full">
                -
              </button>
              {state.quantity}
              <button
                onClick={increaseQuantity}
                className="bg-yellow-500 text-white grid place-content-center p-1 w-[1.5rem] h-[1.5rem] rounded-full">
                +
              </button>
            </div>
          </div>

          <p className="font-semibold md:justify-end">
            Total: <span className="font-normal">{/*Sum*/}$110</span>
          </p>
        </div>

        <div className="grid items-start justify-start font-semibold text-2xl h-full">
          <button>x</button>
        </div>
      </div>

      <hr className="w-full h-[2px] bg-black" />

      <div className="grid justify-end gap-[3rem]">
        <div className="grid gap-[3rem]">
          <div className="flex font-bold justify-between">
            <p>SUBTOTAL</p>
            <p>$110</p>
          </div>
          <div className="flex font-bold justify-between">
            <p>DELIVERY</p>
            <p className="italic">N/A</p>
          </div>
          <hr className="h-[2px] bg-black w-full" />
          <div className="flex font-bold justify-between">
            <p>ESTIMATED TOTAL</p>
            <p>$110</p>
          </div>
        </div>

        <button className="bg-yellow-500 text-white font-bold outline outline-yellow-500 px-6 py-2 rounded-lg outline-[1px] text-xl hover:bg-white hover:text-yellow-500 duration-300 ease-in-outs">
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default ShoppingCart;
