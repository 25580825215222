import React from "react";
import { Link } from "react-router-dom";

const BuyGoldMenu = () => {
  return (
    <div className="rounded-md">
      <ul className="z-100  rounded-sm shadow-md py-4  absolute text-center opacity-[95%]  w-[max-content] gap-4 bg-opacity-60 bg-blue-800 text-white left-0 bottom-[-105px]">
        <li className="p-2 px-6 font-bold w-[max-content]  rounded-md hover:bg-yellow-500">
          <Link to="/goldcoindetail">Gold Coins</Link>
        </li>

        <li className="p-2 px-6 font-bold w-[max-content]  rounded-md hover:bg-yellow-500">
          <Link to="/">Gold Bars</Link>
        </li>

        <li className="p-2 px-6 font-bold w-[max-content]  rounded-md hover:bg-yellow-500">
          <Link to="/">Gold Wallet</Link>
        </li>
      </ul>
    </div>
  );
};

export default BuyGoldMenu;
