import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import ShoppingCart from "./Pages/ShoppingCart";
import TermsAndConditions from "./Components/TermsAndConditions";
import Privacy from "./Components/Privacy";
import AboutUs from "./Components/AboutUs";
import ForgotPassword from "./Components/ForgotPassword";
import { DataProvider } from "./DataContext/DukiaContext";
import Goldbar from "./Pages/Goldbar";
import Goldcoin from "./Pages/Goldcoin";
import Singlegoldbar from "./Pages/Singlegoldbar";
import SingleGoldCoin from "./Pages/SingleGoldCoin";
import LogInAndSignUp from "./Pages/LogInAndSignUp";
import DashBoard from "./Pages/DashBoard";

function App() {
  return (
    <div className="App mx-auto scroll-smooth">
      <DataProvider>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login&signUp" element={<LogInAndSignUp />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/goldbar" element={<Goldbar />} />
          <Route path="/goldcoin" element={<Goldcoin />} />
          <Route path="/goldcoindetail" element={<SingleGoldCoin />} />
          <Route path="/goldbar:id" element={<Singlegoldbar />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/shoppingcart" element={<ShoppingCart />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/terms_and_conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
        <Footer />
      </DataProvider>
    </div>
  );
}

export default App;
