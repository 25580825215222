import React from "react";
import { Link } from "react-router-dom";

const DashBoard = () => {
  return (
    <main className=" md:flex grid lg:w-[80%] xl:w-max-[1155px] w-full p-[2rem]">
      {/* side bar */}
      <section className="h-full md:w-fit w-full">
        <ul className="text-center md:grid md:h-full flex md:gap-[3rem] w-[max-content] justify-between lg:px-[6rem] p-[3rem] font-bold">
          <li className="focus:bg-blue-300">DashBoard</li>
          <li className="focus:bg-blue-300">Buy and Sell</li>
          <li className="focus:bg-blue-300">Transfer</li>
          <li className="focus:bg-blue-300">Redeem</li>
        </ul>
      </section>

      {/* main content */}
      <section className="grid w-full">
        {/* head */}
        <div className="w-full md:flex grid">
          {/* balance */}
          <section className="rounded-lg p-[2rem] w-full grid gap-[2rem] text-center">
            <div className="font-bold">
              <p className="mb-[0.3rem]">Your Total Balance</p>
              <h1 className="text-4xl text-blue-800">
                {/*Balance figure */} N18,456.30
              </h1>
            </div>

            <ul className="flex justify-between text-sm bg-blue-300 p-[1rem] rounded-lg">
              <li className="grid bg-white p-[0.5rem] rounded-md">
                <Link to="/">
                  <p>Deposit</p>
                </Link>
              </li>
              <li className="grid bg-white p-[0.5rem] rounded-md">
                <Link to="/">
                  <p>Withdraw</p>
                </Link>
              </li>
              <li className="grid bg-white p-[0.5rem] rounded-md">
                <Link to="/">
                  <p>More</p>
                </Link>
              </li>
            </ul>
          </section>

          {/* Credit card */}
          <section className="w-full">
            <img
              src="../Img/Debitcard.PNG"
              alt="credit card"
              className="w-full"
            />
          </section>
        </div>

        {/* bottom section */}
        <section className="grid md:grid-cols-3 gap-8">
          <div className="w-full md:col-start-1 md:col-end-3">
            <h1 className="font-bold">Recent Transactions</h1>

            <div className="grid  w-full bg-white md:flex">
              {/* status */}
              <section className="rounded-lg w-full pb-[1rem] overflow-hidden">
                <ul className="flex p-[1.3rem] bg-gray-200/50 font-bold justify-between">
                  <li>Subject</li>
                  <li>Amount</li>
                  <li>Status</li>
                </ul>

                <div>
                  <div className="flex p-[1rem] justify-between">
                    <div className="flex text-sm gap-[0.5rem]">
                      <p className="bg-blue-800 text-center font-bold p-[0.4rem] rounded-full text-white w-fit h-fit">
                        JD
                      </p>
                      <div>
                        <h1 className="font-semibold">Lorem ipsum</h1>
                        <p>Lorem ipsum</p>
                      </div>
                    </div>

                    <p>-12.9003 AU</p>

                    <p className="font-bold text-sm my-auto bg-green-300/50 rounded-lg h-fit px-[1.2rem] py-[0.3rem] text-green-700">
                      Status
                    </p>
                  </div>
                  <hr className="w-[90%] mx-auto bg-black" />
                </div>
                {/* to be deleted */}
                <div>
                  <div className="flex p-[1rem] justify-between">
                    <div className="flex text-sm gap-[0.5rem]">
                      <p className="bg-blue-800 text-center font-bold p-[0.4rem] rounded-full text-white w-fit h-fit">
                        JD
                      </p>
                      <div>
                        <h1 className="font-semibold">Lorem ipsum</h1>
                        <p>Lorem ipsum</p>
                      </div>
                    </div>

                    <p>-12.9003 AU</p>

                    <p className="font-bold text-sm my-auto bg-green-300/50 rounded-lg h-fit px-[1.2rem] py-[0.3rem] text-green-700">
                      Status
                    </p>
                  </div>
                  <hr className="w-[90%] mx-auto bg-black" />
                </div>
              </section>
            </div>
          </div>

          {/* Converter */}
          <section className="w-full rounded-lg overflow-hidden md:col-start-3 md:col-end-4 ">
            <div className="bg-white pt-[1rem] grid gap-4">
              <p className="px-[1rem]">Currency Converter</p>
              <hr className="w-full bg-black" />

              {/* currency */}
              <div className="flex p-[1rem] gap-8">
                <select>
                  <option>NGR</option>
                  <option>USD</option>
                </select>
                {/* to be replaced with an input element */}
                <hr className="w-full my-auto bg-black" />
              </div>

              <div className="px-[1rem]">swap icon {/* icon */}</div>

              {/* product */}
              <div className="flex p-[1rem] gap-8">
                <select>
                  <option>GOLD</option>
                </select>
                {/*  To be replaced with a p tag */}
                <hr className="w-full my-auto bg-black" />
              </div>
            </div>

            <div className="grid gap-[1rem] py-[1rem] bg-gray-200 grid-cols-3 text-center">
              <button>7</button>
              <button>8</button>
              <button>9</button>
              <button>4</button>
              <button>5</button>
              <button>6</button>
              <button>1</button>
              <button>2</button>
              <button>3</button>
              <button>C</button>
              <button>0</button>
              <button>.</button>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
};

export default DashBoard;
