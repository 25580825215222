import React from "react";

const insight = [
  {
    title: "Buy Gold",
    details:
      ": Buy real physical gold of 99.99% stored for you at our 3rd Party LBMA vault",
  },
  {
    title: "Control & Flexibility",
    details:
      "Buy Gold based on your budget, and even better, set up an investment plan",
  },
  {
    title: "Real-time tracking",
    details: "See how your portfolio is doing",
  },
  {
    title: "Sell Gold",
    details: "Sell your gold back to us anytime and get paid instantly",
  },
  {
    title: "Gift Gold",
    details: "Gift gold to your loved ones, friends, colleagues and family",
  },
  {
    title: "Redeem",
    details:
      "Redeem your gold holdings into our wide selection of investment grade gold bars & coins",
  },
];

const Insight = () => {
  return (
    <div id="Insight-tag" className="grid w-[100%] gap-8">
      <section className="w-[40%]  grid gap-2 p-4 mx-auto">
        <hr className="w-14 h-1 bg-black mx-auto" />
        <h1 className=" font-bold lg:text-4xl md:text-3xl text-2xl text-blue-800 text-center">
          Buy gold with ease
        </h1>
        <p className="text-center text-sm md:text-base">
          The Dukia Gold Trading Platform offers an easy, secure and accessible
          way to buy, sell, and gift gold and do more with gold. With our
          platform, you have full control and peace of mind over securing your
          financial future
        </p>
        <hr className="w-14 h-1 bg-black mx-auto" />
      </section>

      <section className="grid w-[80%] mx-auto text-center md:grid-cols-2 lg:grid-cols-3 content-center gap-8">
        {insight.map((item, i) => (
          <article
            key={i}
            className="p-4 ease-in-out duration-300 border border-blue-500 rounded-lg hover:scale-105 hover:shadow-lg shadow-md bg-white bg-opacity-80">
            <h2 className="text-black font-bold text-xl">{item.title}</h2>
            <p className="text-sm md:text-base">{item.details}</p>
          </article>
        ))}
      </section>
    </div>
  );
};

export default Insight;
