import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const GuideMenu = () => {
  return (
    <div>
      <ul className="z-100 rounded-sm shadow-md py-4 absolute text-center opacity-[95%]  w-[max-content] left-0 gap-4 bg-opacity-60 bg-blue-800 text-white bottom-[-135px]">
        <HashLink to="/">
          <li className="p-2 px-6   font-bold rounded-md hover:bg-yellow-500">
            How does it works
          </li>
        </HashLink>
        <HashLink to="/">
          <li className="p-2 px-6 font-bold rounded-md hover:bg-yellow-500">
            Why Invest in Gold
          </li>
        </HashLink>
        <Link to="/">
          <li className="p-2 px-6 font-bold rounded-md hover:bg-yellow-500">
            FAQ
          </li>
        </Link>
        <Link to="/">
          <li className="p-2 px-6 font-bold rounded-md hover:bg-yellow-500">
            Downloads
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default GuideMenu;
