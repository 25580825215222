import React from 'react'
import { useParams } from 'react-router-dom';
import Breadcrumb from '../Components/Breadcrumb';
import {data} from '../Data/GoldBarData';

const Singlegoldcoin = () => {
  const {id} = useParams();
  const bar = data.find(gold=>gold.id === id)
  return (
    <main className=' w-[90%] mx-auto'>
      <Breadcrumb/>
    </main>
  )
}

export default Singlegoldcoin