import React, { useContext } from "react";
import Login from "../Components/Login";
import Register from "../Components/Register";
import DataContext from "../DataContext/DukiaContext";

const LogInAndSignUp = () => {
  const { SignUp, LogIn, state } = useContext(DataContext);
  return (
    <div className="grid w-full p-16 ">
      <div className="flex justify-center">
        <button
          onClick={LogIn}
          className={`outline py-2 px-8 ${
            !state.hasAccount
              ? "bg-yellow-500 text-white"
              : "bg-none text-black"
          } outline-[1.5px] outline-yellow-500 rounded-l-lg`}>
          Log In
        </button>
        <button
          onClick={SignUp}
          className={`outline py-2 px-8 ${
            state.hasAccount ? "bg-yellow-500 text-white" : "bg-none text-black"
          } outline-[1.5px] outline-yellow-500 rounded-r-lg`}>
          Sign Up
        </button>
      </div>
      <div>{state.hasAccount ? <Login /> : <Register />}</div>
    </div>
  );
};

export default LogInAndSignUp;
