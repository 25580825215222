import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <p className="text-3xl">Terms & Conditions</p>
    </div>
  );
};

export default TermsAndConditions;
