import React from "react";
import { data } from "../Data/GoldCoinData";
const DisplayGoldCoin = ({ View }) => {
  return (
    <div className="overflow-x-scroll scroll whitespace-nowrap scrollbar-hide w-full flex gap-6">
      {data.map((data) => (
        <article
          key={data.id}
          onClick={View}
          className="flex gap-2 items-center h-full justify-between rounded-lg shadow-md bg-white flex-col p-3 md:w-[20vw] w-[15rem]">
          <img src={data.image} alt="" width={80} height={100} />
          <div className="grid gap-2">
            <p className="font-bold">{data.title}</p>
            <div className="flex w-full justify-end">
              <button className="px-6 py-1 rounded-md text-white hover:scale-105 hover:text-yellow-500 bg-yellow-500 hover:bg-white outline outline-1 outline-yellow-500 w-[max-content]">
                Buy
              </button>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};
const SingleGoldCoin = () => {
  let index = 0;

  return (
    <main className="grid gap-8 p-6 w-full">
      <section className="grid md:grid-cols-2 p-4 gap-6">
        <div className="shadow-md bg-white md:py-0 py-[8rem] grid place-content-center">
          <img src={data[index]["image"]} alt="" width={100} />
        </div>
        <div className="grid py-6 gap-y-4">
          <p className="text-gray-400 font-bold">Gold Coin</p>
          <h1 className="text-xl md:3xl font-bold">{data[index].title}</h1>
          <p className="font-semibold text-xl md:text-3xl">$110</p>
          <p>{data[index].description.slice(0, 100)}</p>

          <div className="grid gap-y-6">
            <hr className="h-[1px w-full " />
            <div className="flex gap-4">
              <div className="flex justify-center gap-4 content-center">
                <p className="font-semibold mr-8">QUALITY:</p>
                <button className="bg-yellow-500 rounded-full grid place-content-center h-[1.5rem] w-[1.5rem] text-white p-1">
                  -
                </button>
                <p>0</p>
                <button className="bg-yellow-500 rounded-full grid place-content-center h-[1.5rem] w-[1.5rem] text-white p-1">
                  +
                </button>
              </div>
            </div>
            <hr className="h-[1px] w-full " />
            <button
              type="submit"
              className="font-bold px-8 py-2 w-fit bg-yellow-500 text-white rounded-lg">
              BUY NOW
            </button>
          </div>
        </div>
      </section>

      <article className="flex-col flex md:flex-row gap-y-4 gap-2">
        <div>
          <div className="flex justify-between font-bold p-4">
            <p className="text-gray-400 focus:text-blue-900 focus:underline focus:underline-offset-2 focus:underline-blue-900">
              Product Description
            </p>
            <p className="text-gray-400 focus:text-blue-900 focus:underline focus:underline-offset-2 focus:underline-blue-900">
              Product Description
            </p>
            <p className="text-gray-400 focus:text-blue-900 focus:underline focus:underline-offset-2 focus:underline-blue-900">
              Product Description
            </p>
          </div>

          <hr className="h-[1px w-full bg-gray-400" />

          <div>
            <p className="font-bold text-xl pb-2">{data[index]["title"]}</p>
            <p className="font-bold text-sm ">{data[index]["description"]}</p>
          </div>
        </div>

        <div className="grid bg-gray-300 w-fit gap-4 rounded-lg h-fit p-4">
          <p className="font-bold text-center text-sm mb-4">Specifications</p>
          <div className="font-bold flex md:justify-between gap-2 px-6 text-sm ">
            <p>manufacturer</p>
            <p className="text-gray-400 ">{data[index]["manufacturer"]}</p>
          </div>
          <hr className="bg-black h-[1px] w-full" />

          <div className="font-bold flex md:justify-between gap-2 text-sm ">
            <p>country</p>
            <p className="text-gray-400 ">{data[index]["country"]}</p>
          </div>
          <hr className="bg-black h-[1px] w-full" />

          <div className="font-bold flex md:justify-between gap-2 text-sm ">
            <p>Denomination</p>
            <p className="text-gray-400 ">Naira</p>
          </div>
          <hr className="bg-black h-[1px] w-full" />

          <div className="font-bold flex md:justify-between gap-2 text-sm ">
            <p>Year of Issue</p>
            <p className="text-gray-400 ">-</p>
          </div>
          <hr className="bg-black h-[1px] w-full" />

          <div className="font-bold flex md:justify-between gap-2 text-sm ">
            <p>weight</p>
            <p className="text-gray-400 ">{data[index]["grossWeight"]}</p>
          </div>
          <hr className="bg-black h-[1px] w-full" />

          <div className="font-bold flex md:justify-between gap-2 text-sm ">
            <p>Fineness</p>
            <p className="text-gray-400 ">{data[index]["fineness"]}</p>
          </div>
          <hr className="bg-black h-[1px] w-full" />
        </div>
      </article>

      <section className="grid text-center gap-6">
        <div className="mx-auto gap-2 grid">
          <hr className="w-[3rem] mx-auto rounded-lg h-[0.3rem] bg-black" />
          <h1 className="text-blue-800 font-bold text-3xl">
            You May Also Like...
          </h1>
          <p>
            looihg hg ijhg iujhg oiuygf iuyg ug iugfd iuyg h iujhgfdfgyhu
            hgfdfghjk juhgfd
          </p>
          <hr className="w-[3rem] mx-auto rounded-lg h-[0.3rem] bg-black" />
        </div>

        <DisplayGoldCoin />
      </section>
    </main>
  );
};

export default SingleGoldCoin;
