import React from "react";
import { Link, NavLink } from "react-router-dom";

const Information = () => {
  return (
    <div className="grid gap-2">
      <p>
        <strong className="text-white  text-xl">Information</strong>
      </p>
      <ul className="flex flex-col gap-2 md:xl">
        <li className="w-[fit-content] hover:text-blue-300">
          <Link to="/Terms_and_Conditions">Terms & Conditions</Link>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <Link to="/Privacy">Cookies & Privacy</Link>
        </li>
        <li className=" w-[fit-content] hover:text-blue-300">
          <Link to="/AboutUs">About Us</Link>
        </li>
        <li className="w-[fit-content] hover:text-blue-300">
          <Link to="/#Guide">Guide</Link>
        </li>
      </ul>
    </div>
  );
};

export default Information;
