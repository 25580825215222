import React from "react";

const features = [
  {
    img: "https://pbs.twimg.com/profile_images/2478953412/tze8i93mwynorieaezpq_400x400.jpeg",
    link: "https://www.thisdaylive.com/index.php/2022/05/15/dukia-gold-philoro-sign-agreement-to-deepen-precious-metals-value-chain/",
  },
  {
    img: "https://imgs.search.brave.com/gaf6TSRsDdpKzeThaaPDS0zD6mIz-XlvJ3NPSe9VlPY/rs:fit:693:200:1/g:ce/aHR0cHM6Ly9jZG4u/dmFuZ3VhcmRuZ3Iu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDE2LzA2L3Zhbmd1/YXJkbG9nby5wbmc",
    link: "https://www.vanguardngr.com/2022/06/dukia-to-trade-gold-bullion-bars-on-lagos-commodities-exchange/",
  },
  {
    img: "https://imgs.search.brave.com/oCh_rg0oM0CMQYrO-TbfLG2f9oAhJ64Lk6CLMbOCOs4/rs:fit:3077:225:1/g:ce/aHR0cHM6Ly90c2Ux/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC50/QzQwVHBDYko2Q29v/RnRSRkNFUHdBSGFC/SiZwaWQ9QXBp",
    link: "https://businessday.ng/news/article/fg-launches-dukia-gold-creates-first-precious-metal-refining-market/",
  },
  {
    img: "https://imgs.search.brave.com/EMV5-yUuMMNkzItaGVm8IXej8hnV5nZB1WjZAgIVEqc/rs:fit:1200:417:1/g:ce/aHR0cHM6Ly9jZG4u/ZnJlZWJpZXN1cHBs/eS5jb20vaW1hZ2Vz/L2xhcmdlLzJ4L3Ro/ZS1ndWFyZGlhbi1s/b2dvLXRyYW5zcGFy/ZW50LnBuZw",
    link: "https://guardian.ng/tag/dukia-gold-precious-metals-refinery-project/",
  },
  {
    img: "https://imgs.search.brave.com/hxKKPr1EDCot6eMieCyr-Uy6a0wBGAiMaF40Rdeb_0M/rs:fit:474:225:1/g:ce/aHR0cHM6Ly90c2Ux/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5k/RmJ3SEVYdzBpdDNK/ZWFERU41NlhnSGFI/YSZwaWQ9QXBp",
    link: "https://www.nipc.gov.ng/2022/05/14/dukia-gold-philoro-collaborate-to-boost-precious-metals-value-chain/",
  },
  {
    img: "https://independent.ng/wp-content/uploads/INL-MASTHEAD-Logo-scaled.jpg",
    link: "https://independent.ng/dukia-to-trade-gold-bullion-bars-on-lagos-commodities-exchange/",
  },
  {
    img: "https://www.businessamlive.com/wp-content/uploads/2021/10/business-am-logo-white.png",
    link: "https://www.businessamlive.com/commodities-ecosystem-livens-up-as-dukia-trade-gold-on-lcfe/",
  },
  {
    img: "https://dmarketforces.com/wp-content/uploads/2020/08/logo-for-web.png",
    link: "https://dmarketforces.com/dukia-to-trade-gold-bullion-bars-on-lagos-commodities-exchange/",
  },
  {
    img: "https://imgs.search.brave.com/yk-4-cQqpLJwNCWpaW8XrHDYWj4mMnW98qEYnrA2uJY/rs:fit:1255:225:1/g:ce/aHR0cHM6Ly90c2Uz/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5C/X01ORlE3c1c3RW5s/Z1ZVdGVLb2pnSGFD/eiZwaWQ9QXBp",
    link: "https://allafrica.com/stories/202006100717.html",
  },
  {
    img: "https://nigeriatransporthub.com.ng/wp-content/uploads/2020/02/cropped-logoo-2.png",
    link: "https://nigeriatransporthub.com.ng/sec-endorses-dukia-gold-sole-dealer-refiner-trader-in-gold-as-precious-metal/",
  },
];
const FeaturedIn = () => {
  return (
    <div className="grid h-[max-content] scroll overflow-x-scroll whitespace-nowrap scrollbar-hide scroll-smooth">
      {/* <section className="grid place-content-center gap-2">
        <hr className="w-10 h-1 bg-black mx-auto" />
        <h2 className="text-black md:text-2xl text-center text-xl">
          As Featured in
        </h2>
        <hr className="w-10 h-1 bg-black mx-auto" />
      </section> */}

      <section className="flex relative items-center">
        <div>
          {features.map((feature, i) => (
            <a
              href={feature.link}
              key={i}
              target="_blank"
              rel="noReferrer"
              className="lg:w-[20rem] md:w-[15rem] w-[10rem] inline-block hover:scale-105 p-4 cursor-pointer ease-in-out duration-300">
              <img src={feature.img} alt="" />
            </a>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FeaturedIn;
