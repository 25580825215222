import React from "react";
import Hero from "../Components/Hero";
import Product from "../Components/Products";
import WhyInvest from "../Components/WhyInvest";
import CustomerReview from "../Components/CustomerReview";
import Consultation from "../Components/Consultation";
import Faq from "../Components/Faq";
import Insight from "../Components/Insight";
import FeaturedIn from "../Components/FeaturedIn";
import { Link } from "react-router-dom";

const GetStarted = () => {
  return (
    <>
      <Link to="/Register" className="mx-auto">
        <button className="px-6 py-4 rounded-lg shadow-md text-bold md:text-4xl lg:text-6xl text-2xl text-white hover:text-yellow-500 bg-yellow-500 hover:bg-white outline-[1px] outline outline-yellow-500">
          Get started in a minute
        </button>
      </Link>
    </>
  );
};

const Home = () => {
  return (
    <div className="grid gap-[6rem]">
      <Hero />
      <Insight />
      <GetStarted />
      <FeaturedIn />
      <Product />
      <WhyInvest />
      <CustomerReview />
      <Consultation />
      <Faq />
    </div>
  );
};

export default Home;
