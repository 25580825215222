import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../DataContext/DukiaContext";

const Login = () => {
  const { showProfileBar } = useContext(DataContext);
  return (
    <div className="h-[max-content]  gap-6 w-fit bg-white mx-auto my-10 place-content-center shadow-lg ">
      <form
        onClick={(e) => e.preventDefault()}
        className="flex h-[100%] content-center flex-col">
        {/* <div className=" bg-blue-900 w-[100%] min-h-[100%] flex flex-col content-center text-center justify-center gap-4 p-5 text-white">
          <hr className="w-[3vw] h-[1.2px]  mx-auto rounded-full bg-white" />
          <strong className="text-2xl">WELCOME TO DUKIA</strong>
          <p className="mt-[-8px]">Login to your Account</p>
          <hr className="w-[3vw] h-1 mx-auto rounded-full bg-white" />
        </div> */}

        <div className=" h-[fit-content]  px-8 w-[100%] grid gap-5 py-10">
          <div className="grid text-center">
            <h1>
              <strong className="text-xl text-blue-800">
                Sign into Your Account
              </strong>
            </h1>
            <p className="pb-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididun
            </p>

            <hr className="w-[4vw] h-1.5 mx-auto rounded-full bg-red-900" />
          </div>

          <label className="grid gap-3">
            <strong>Email*</strong>
            <input
              type="email"
              required
              placeholder="example@gmail.com"
              className=" border-[1px] focus:shadow-md outline-none rounded-lg px-2 p-2"
            />
          </label>

          <label className="grid gap-3">
            <strong>Password*</strong>
            {/* Allow password to be visible */}
            <input
              type="password"
              required
              placeholder="Your Password"
              className="focus:shadow-md border rounded-lg outline-none px-2 p-2"
            />
          </label>

          <Link to="/ForgotPassword">
            <p className="text-yellow-500 text-end">
              <strong>forgot Password?</strong>
            </p>
          </Link>
          <Link to="/dashboard" className="mx-auto">
            <button
              onClick={() => showProfileBar()}
              type="submit"
              className="text-md px-10 mx-auto hover:bg-white hover:border-yellow-500 hover:outline hover:outline-[1px] hover:text-yellow-500 w-[fit-content] p-1 bg-yellow-500 rounded-md text-white">
              LOGIN
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
