import React, { useRef, useEffect, useContext } from "react";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import MenuBar from "./MenuBar";
import SideBar from "../SideBar";
import DataContext from "../../DataContext/DukiaContext";

const Left = () => {
  const { closeSideBar, state, toggleSideBar } = useContext(DataContext);

  let bar = useRef();
  useEffect(() => {
    let checkHandler = (event) => {
      if (!bar.current.contains(event.target)) {
        closeSideBar();
      }
    };

    document.addEventListener("mousedown", checkHandler);

    return () => {
      document.removeEventListener("mousedown", checkHandler);
    };
  });

  return (
    <>
      <div className="flex justify-start md:text-sm text-base md:gap-4 xl:gap-6 md:w-full w-[fit-content] relative">
        <div ref={bar} className="relative flex-col flex">
          <BiMenu
            onClick={() => toggleSideBar()}
            size={25}
            className="md:hidden md:mr-15 mr-6 w-[fit content] outline-none hover:outline hover:outline-yellow-500"
          />
          {state.SideBar && <SideBar />}
        </div>

        <div className="flex justify-between md:mr-14 align-bottom">
          <div className="w-[100%]">
            <Link to="/">
              <img
                alt="Dukia"
                className="w-[40px]"
                src="https://app.dukiagold.com/img/brand/logo-white-horizontal.svg"
              />
            </Link>
          </div>
        </div>
        <MenuBar />
      </div>
    </>
  );
};

export default Left;
